.DifficultyBlack {
    color: rgb(0, 0, 0) !important;
}
.DifficultyGrey {
    color: rgb(128, 128, 128) !important;
}
.DifficultyBrown {
    color: rgb(128, 64, 0) !important;
}
.DifficultyGreen {
    color: rgb(0, 128, 0) !important;
}
.DifficultyCyan {
    color: rgb(0, 192, 192) !important;
}
.DifficultyBlue {
    color: rgb(0, 0, 255) !important;
}
.DifficultyYellow {
    color: rgb(192, 192, 0) !important;
}
.DifficultyOrange {
    color: rgb(255, 128, 0) !important;
}
.DifficultyRed {
    color: rgb(255, 0, 0) !important;
}

.DifficultyDisplayer {
    display: inline-block;
	border-radius: 50%;
	border-style: solid;
	border-width: 1px;
	margin-right: 5px;
	margin-left: 1px;
	height: 12px;
	width: 12px;
}


.LargeDifficultyDisplayer {
    display: inline-block;
	border-radius: 50%;
	border-style: solid;
	border-width: 1.667px;
	margin-right: 5px;
	margin-left: 1px;
	height: 20px;
	width: 20px;
}

.SmallDifficultyDisplayer {
    display: inline-block;
	border-radius: 50%;
	border-style: solid;
	border-width: 1.667px;
	margin-right: 5px;
	margin-left: 1px;
	height: 9px;
	width: 9px;
}