.footer {
    margin-top: 15px !important;
}

.version-text {
    font-size: 14px;
    margin-left: 1em;
    margin-right: 1em;
    color: #777;
    display: inline-block;
}
.version-text code {
    margin-left: 5px;
}
.version-text code a {
    color: #777;
}

.icon-links {
    margin-top: 1em;
    font-size: 24px;
}
.icon-links a {
    color: #000;
}
